<template>
  <div class="search-condition-wrap">
    <div class="button-wrap">
      <el-button type="primary" style="width: 110px" @click="openDialog('searchReport')">修改條件</el-button>
    </div>
    <el-descriptions :column="1" size="large" style="width: 100%" v-if="getSearchCondition">
      <el-descriptions-item label-class-name="descriptions-label" label="交易日期" min-width="260px">
        {{ getDateText(getSearchCondition.date) }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" class-name="descriptions-content" label="門市">
        {{
          getSearchCondition.shop_id.length > 0
            ? getSearchCondition.shop_id.map((e) => e.split(' ')[1] + ' ' + e.split(' ')[2]).join('/')
            : '未設定'
        }}
      </el-descriptions-item>
    </el-descriptions>
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
// import { useRoute, useRouter } from 'vue-router';
import { useGetters } from '@/utils/mapStore';
import { defineAsyncComponent } from 'vue';
import { useDialog } from '@/utils/dialog';
import { getFormatDate } from '@/utils/datetime';
export default {
  name: 'search-report',
  components: {
    ReportDialog: defineAsyncComponent(() => import('@/components/report/ReportDialog.vue')),
  },
  setup() {
    //取得條件
    const getSearchCondition = useGetters('report', ['getSearchCondition']);
    const getDateText = (arr) => {
      if (!arr || arr.length === 0) return '未設定';
      const d = getFormatDate(-1);
      if (arr.every((e) => d === e)) return '前一天';
      else return arr[0] + ' ~ ' + arr[1];
    };
    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return { getSearchCondition, dialogTemplate, openDialog, getDateText };
  },
};
</script>
<style lang="scss" scoped>
.search-condition-wrap {
  @include white-bg;
  display: flex;
  align-items: center;
  padding: 16px 22px 0;
  position: relative;
  .button-wrap {
    position: absolute;
    top: 16px;
    right: 22px;
  }
  :deep(.descriptions-content) {
    width: calc(100vw - 440px);
  }
}
</style>
